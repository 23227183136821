export enum PurchaseAgreementStatus {
  PENDING = "PENDING",
  FAILED = "FAILED",
  DONE = "DONE"
}

export interface PurchaseAgreement {
  _id: string;
  createdAt: string;
  updatedAt: string;
  status: PurchaseAgreementStatus;
  mediaId?: string;
}

export const PurchaseAgreementStatusColor: Record<PurchaseAgreementStatus, string> = {
  [PurchaseAgreementStatus.DONE]: "green-light",
  [PurchaseAgreementStatus.FAILED]: "red-light",
  [PurchaseAgreementStatus.PENDING]: "blue-light"
};

export const PurchaseAgreementStatusLabel: Record<PurchaseAgreementStatus, string> = {
  [PurchaseAgreementStatus.DONE]: "Processed",
  [PurchaseAgreementStatus.FAILED]: "Failed",
  [PurchaseAgreementStatus.PENDING]: "Pending"
};
