<div class="flex-box-center-between mb-4">
  <p class="fw-600 text-size-22">Send a Message</p>
  <button class="button-icon close ml-auto close-button" mat-dialog-close>
    <app-icon class="text-size-28">close</app-icon>
  </button>
</div>

<mat-dialog-content>
  <form #contactAgentForm="ngForm">
    <div class="flex-box flex-box-gap-2">
      <div class="form-field mb-4">
        <label for="form-first-name">First name</label>
        <input
          placeholder="First name"
          name="firstName"
          type="text"
          id="form-first-name"
          #firstNameField="ngModel"
          [maxlength]="49"
          required
          [(ngModel)]="data.firstName"
        />
        <div *ngIf="firstNameField.invalid && firstNameField.touched" class="text-size-12 text-color-red mt-1">
          <div *ngIf="firstNameField.errors!.required">Enter a First Name.</div>
          <div *ngIf="firstNameField.errors!.maxlength">First name cannot have more than 49 characters.</div>
        </div>
      </div>

      <div class="form-field mb-4">
        <label for="form-last-name">Last name</label>
        <input
          placeholder="Last name"
          name="lastName"
          type="text"
          id="form-last-name"
          #lastNameField="ngModel"
          [maxlength]="49"
          required
          [(ngModel)]="data.lastName"
        />
        <div *ngIf="lastNameField.invalid && lastNameField.touched" class="text-size-12 text-color-red mt-1">
          <div *ngIf="lastNameField.errors!.required">Enter a Last Name.</div>
          <div *ngIf="lastNameField.errors!.maxlength">Last name cannot have more than 49 characters.</div>
        </div>
      </div>
    </div>

    <div class="flex-box flex-box-direction-column flex-box-gap-2 flex-box-lg-direction-row mb-4">
      <div class="form-field">
        <label>Email</label>
        <input type="email" placeholder="Email" name="email" disabled [value]="contactEmail" required class="disabled-grey" />
      </div>
      <div class="form-field">
        <label>Phone</label>
        <input
          type="text"
          [(ngModel)]="data.phone"
          #phone="ngModel"
          [pattern]="phoneNumberPattern"
          libFormatPhone
          placeholder="Phone"
          name="phone"
          required
        />
        <div *ngIf="phone.invalid && phone.touched" class="text-size-12 text-color-red mt-1">
          <div *ngIf="phone.errors!">Enter a valid phone.</div>
        </div>
      </div>
    </div>

    <div class="flex-box-direction-row mb-4">
      <div class="form-field w-100">
        <label>Are you considering selling your home? If yes, what is your potential timeframe? </label>
        <div class="flex-box-direction-row flex-box-gap-2 flex-wrap">
          <div class="flex-box-center">
            <input
              type="radio"
              id="option0"
              class="mr-2 w-auto"
              name="home-buy"
              [value]="SaleTimeline.ASAP"
              [(ngModel)]="data.saleTimeline"
              #sellingTimeline="ngModel"
              required
            />
            <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option0">
              {{ SaleTimeline.ASAP | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}
            </label>
          </div>
          <div class="flex-box-center">
            <input
              type="radio"
              id="option1"
              class="mr-2 w-auto"
              name="home-buy"
              [value]="SaleTimeline.LT3M"
              [(ngModel)]="data.saleTimeline"
              #sellingTimeline="ngModel"
              required
            />
            <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option1">
              {{ SaleTimeline.LT3M | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}</label
            >
          </div>
          <div class="flex-box-center">
            <input
              type="radio"
              id="option2"
              class="mr-2 w-auto"
              name="home-buy"
              [value]="SaleTimeline.B3TO6M"
              [(ngModel)]="data.saleTimeline"
              #sellingTimeline="ngModel"
              required
            />
            <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option2">
              {{ SaleTimeline.B3TO6M | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}
            </label>
          </div>
          <div class="flex-box-center">
            <input
              type="radio"
              id="option3"
              class="mr-2 w-auto"
              name="home-buy"
              [value]="SaleTimeline.B6TO12M"
              [(ngModel)]="data.saleTimeline"
              #sellingTimeline="ngModel"
              required
            />
            <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option3">
              {{ SaleTimeline.B6TO12M | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}
            </label>
          </div>
          <div class="flex-box-center">
            <input
              type="radio"
              id="option4"
              class="mr-2 w-auto"
              name="home-buy"
              [value]="SaleTimeline.NA"
              [(ngModel)]="data.saleTimeline"
              #sellingTimeline="ngModel"
              required
            />
            <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option4">{{
              SaleTimeline.NA | enumToLabel: "FELLO_SALE_TIMELINE_SHORT"
            }}</label>
          </div>
        </div>
        <div
          *ngIf="sellingTimeline.control.touched && sellingTimeline.errors?.required"
          class="error-field text-size-12 text-color-red mt-1"
        >
          <div>Please select an option.</div>
        </div>
      </div>
    </div>
    <label>Message</label>
    <div class="form-field w-100">
      <textarea [(ngModel)]="data.message" name="message" placeholder="Message..." #message [maxlength]="2500"></textarea>
      <div class="textarea-count text-size-10 text-color-light">{{ message.textLength }}/2500</div>
    </div>
    <p class="text-size-12 text-color-light-2" *ngIf="consentText$ | async; let consentText">
      <input class="consent-checkbox mr-1 w-auto" checked disabled type="checkbox" />
      <span dynamicTooltipDirective class="link-underline" [innerHTML]="consentText | safeHtml"></span>
    </p>
    <ngx-skeleton-loader count="1" *ngIf="isConsentTextLoading" [theme]="{height: '16px', width: '100%'}"></ngx-skeleton-loader>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="mt-3">
  <button
    class="w-100"
    (click)="contactAgentForm.control.markAllAsTouched(); contactAgentForm.valid && submitCTAPopupLeadForm()"
    [disabled]="isConsentTextLoading"
  >
    Send
  </button>
</mat-dialog-actions>

<ngx-spinner [name]="spinnerName" [fullScreen]="true"></ngx-spinner>
