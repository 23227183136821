export enum FieldFilterDynamicListType {
  CONTACT_TAGS = "CONTACT_TAGS",
  CONTACT_OWNERS = "CONTACT_OWNERS",
  CONTACT_CRM_AGENTS = "CONTACT_CRM_AGENTS",
  CONTACT_CRM_NAMES = "CONTACT_CRM_NAMES",
  CONTACT_CRM_SOURCES = "CONTACT_CRM_SOURCES",
  CONTACT_CRM_STAGES = "CONTACT_CRM_STAGES",
  ORIGIN_IDS = "ORIGIN_IDS"
}

export enum FieldCRMFilterDynamicListType {
  fubStages = "fubStages",
  fubUsers = "fubUsers",
  sierraListingUsers = "sierraListingUsers",
  sierraLeadStatus = "sierraLeadStatus",
  sierraLeadSources = "sierraLeadSources",
  sierraLeadTags = "sierraLeadTags",
  boldtrailContactStatus = "boldtrailContactStatus",
  boldtrailUsers = "boldtrailUsers"
}

export enum FieldCRMStatus {
  DoNotContact = "DoNotContact"
}

export const FieldCRMStatusToLabelMapping: Record<string, string | undefined> = {
  [FieldCRMStatus.DoNotContact]: "Do Not Contact"
};
