import {FQLFieldExpr, FQLOrExpr} from "../mvc";
import {WorkflowEventType} from "./WorkflowEvent";
import {ChangelogTriggeredBy} from "../deal";
import {NumberOrUnlimited} from "../number";
import {ContentMedium} from "../content";

export enum AutomationType {
  MARKETING = "MARKETING",
  WORKFLOW = "WORKFLOW"
}

export interface AutomationFilterCriteria {
  defaultCriteria?: FQLOrExpr;
  userCriteria?: FQLOrExpr;
}

export enum AutomationEnrollmentStrategy {
  CONTACT = "CONTACT",
  PROPERTY = "PROPERTY"
}

export enum AutomationEnrollmentType {
  EVENT = "EVENT",
  CRITERIA = "CRITERIA"
}

export class AutomationEventEnrollmentInfo {
  eventType: WorkflowEventType;
  eventCriteria?: FQLOrExpr;
}

export interface AutomationEnrollmentInfo {
  events?: AutomationEventEnrollmentInfo[];
  filterCriteria: AutomationFilterCriteria;
}

export enum AutomationStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DRAINING = "DRAINING",
  ARCHIVED = "ARCHIVED"
}

export enum AutomationUnenrollByEnrollCriteria {
  ON = "ON",
  OFF = "OFF"
}

export interface AutomationWatchExpressions {
  expressions: FQLFieldExpr[];
}

export interface AutomationReEnrollmentInfo {
  enabled: boolean;
  watchExpressions: AutomationWatchExpressions;
}

export interface AutomationUnenrollmentInfo {
  unenrollByEnrollCriteria: AutomationUnenrollByEnrollCriteria;
  customCriteria?: FQLOrExpr;
}

export enum AutomationFlowchartZoomModes {
  WHEEL = "WHEEL",
  MANUAL = "MANUAL",
  DISABLED = "DISABLED"
}

export interface AutomationFlowchartZoomOptions {
  mode: AutomationFlowchartZoomModes;
  defaultScale?: number;
  defaultStep?: number;
  skipRender?: boolean;
  maxScale?: number;
  minScale?: number;
}

export interface AutomationFlowchartDiagMeta {
  zoom?: AutomationFlowchartZoomOptions;
  stepGap?: number;
}

export interface AutomationDiagMeta {
  diagProvider: string;
  meta?: AutomationFlowchartDiagMeta;
}

export interface WorkflowExhaustionCheckData {
  agencyWorkflowLimit: NumberOrUnlimited;
  activeWorkflowsCount: number;
  isWorkflowExhaustedForAgency: boolean;
}

export interface AutomationDefinition {
  _id: string;
  title: string;
  description?: string;
  type: AutomationType;
  agencyId: string;
  recipeDedupeId?: string;
  status: AutomationStatus;
  enrollmentStrategy: AutomationEnrollmentStrategy;
  enrollmentType: AutomationEnrollmentType;
  enrollmentInfo: AutomationEnrollmentInfo;
  reEnrollmentInfo: AutomationReEnrollmentInfo;
  unenrollmentInfo: AutomationUnenrollmentInfo;
  createdAt: Date;
  createdBy?: ChangelogTriggeredBy;
  updatedAt: Date;
  diag?: AutomationDiagMeta;
  terminalStatus?: AutomationStatus;
  contentMedium?: ContentMedium[];
}

export const AutomationStatusLabel: Record<AutomationStatus, string> = {
  [AutomationStatus.ACTIVE]: "ACTIVE",
  [AutomationStatus.INACTIVE]: "INACTIVE",
  [AutomationStatus.DRAINING]: "DRAINING",
  [AutomationStatus.ARCHIVED]: "ARCHIVED"
};
