import {AfterViewInit, Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewContainerRef} from "@angular/core";
import {LandingPageLayoutType, LandingPageSettingsResponse} from "fello-model";
import {AbstractPublicLandingPageService} from "../../services";
import {map, take, takeUntil, tap} from "rxjs/operators";
import {DestroyableToastBase, FelloWebsites} from "../../../../lib";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ContactDialogComponent} from "../contact-dialog/contact-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {DOCUMENT, isPlatformBrowser} from "@angular/common";
import {BreakpointObserver} from "@angular/cdk/layout";

export const defaultResponsiveStyleSheet = `<style>
body {margin: 0;}
.vc {position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden;}
.vc iframe, .vc object, .vc embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
</style>`;

export const defaultStyleSheet = `<style>
body {margin: 0;}
</style>`;

@Component({
  selector: "lib-landing-page-template-a",
  templateUrl: "./landing-page-template-a.component.html",
  styleUrls: ["./landing-page-template-a.component.scss"],
  host: {
    class: "position-relative"
  }
})
export class LandingPageTemplateAComponent extends DestroyableToastBase implements OnInit, AfterViewInit {
  landingPageSetting?: LandingPageSettingsResponse<LandingPageLayoutType.WEBSITE_1>;
  address: string;
  isLoading = false;
  isContactDialogOpen = false;
  felloWebsites = FelloWebsites;
  isDesktop$ = this.breakpointObserver.observe("(min-width: 992px)").pipe(map(state => state.matches));
  hasTrackedView = false;

  constructor(
    private publicLandingPageService: AbstractPublicLandingPageService<LandingPageLayoutType.WEBSITE_1>,
    @Inject(DOCUMENT) private document: Document,
    snackBar: MatSnackBar,
    private vcr: ViewContainerRef,
    private breakpointObserver: BreakpointObserver,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
    private dialog: MatDialog
  ) {
    super(snackBar);
  }

  get iframeSrc(): string {
    const scriptUrl = new URL(this.document.location.href);
    scriptUrl.pathname = "/assets/js/iframeResizer.contentWindow.min.js";
    scriptUrl.search = "";
    scriptUrl.hash = "";

    if (this.landingPageSetting?.config.layoutSettings.videoEmbedCode?.trim().startsWith("<div")) {
      return `
        ${defaultStyleSheet}
        <script src="${scriptUrl.toString()}"></script>
        ${this.landingPageSetting?.config.layoutSettings.videoEmbedCode}
    `;
    }
    return `
    ${defaultResponsiveStyleSheet}
    <script src="${scriptUrl.toString()}"></script>
    <div class="vc">
      ${this.landingPageSetting?.config.layoutSettings.videoEmbedCode}
    </div>
    `;
  }

  ngOnInit(): void {
    this.publicLandingPageService.landingPagePublicSetting$
      .pipe(
        takeUntil(this.isDestroyed),
        tap(landingPageSetting => {
          this.landingPageSetting = landingPageSetting;
        })
      )
      .subscribe();
  }

  submitForm(): void {
    this.isLoading = true;
    this.withToast(this.publicLandingPageService.performCTAAction(this.landingPageSetting!.config.layoutSettings.action, this.address), {
      failureMsg: "Unable to submit form. please try again later."
    }).subscribe(
      ({url}) => {
        window.location.href = url;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  openContactBlock(): void {
    this.isContactDialogOpen = true;
    this.dialog
      .open(ContactDialogComponent, {
        data: this.landingPageSetting?.signature,
        panelClass: "contact-block-dialog",
        backdropClass: "opacity-lg-0",
        viewContainerRef: this.vcr
      })
      .afterClosed()
      .pipe(tap(() => (this.isContactDialogOpen = false)))
      .subscribe();
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId) && !this.hasTrackedView) {
      this.hasTrackedView = true;
      this.publicLandingPageService.trackView().pipe(take(1)).subscribe();
    }
  }
}
