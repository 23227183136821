import {AutomationComponentType} from "fello-model";

export const POSTCARD_CREDITS = 10;
export const ENRICHMENT_CREDITS = 1;

export const DNC_CREDITS = 1;

export const DNC_CREDIT_CONSUMPTION_BATCH_SIZE = 3;

export const LOW_CREDIT_THRESHOLD = 50;

export const ActionableComponentTypeCredits: Record<string, number> = {
  [AutomationComponentType.POSTCARD]: POSTCARD_CREDITS,
  [AutomationComponentType.CALL_WEBHOOK]: ENRICHMENT_CREDITS
};
