import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AgentLeadType, ChangelogEventType, ContactAgentFromDashboardRequest, FelloUserContactDetails} from "fello-model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgxSpinnerService} from "ngx-spinner";
import {AgentContactDashboardApiService, ConsumerConsentService, FelloWebsites, StringUtils, ToastSpinnerBase} from "../../../../../lib";
import {distinctUntilKeyChanged, map, shareReplay, switchMap, take, tap} from "rxjs/operators";
import {phoneNumberPattern} from "../../../../fello-ui-utils";
import {AbstractDashboardService, DashboardSettingsService} from "../../../services";
import {combineLatest, defer} from "rxjs";

@Component({
  selector: "lib-contact-agent",
  templateUrl: "./contact-agent.component.html",
  styleUrls: ["./contact-agent.component.scss"]
})
export class ContactAgentComponent extends ToastSpinnerBase {
  data: ContactAgentFromDashboardRequest = {
    firstName: "",
    lastName: "",
    phone: ""
  };
  phoneNumberPattern = phoneNumberPattern;
  settings$ = this.dashboardSettingsService.settings$;
  isConsentTextLoading = false;
  consentText$ = defer(() => {
    return combineLatest([
      this.dashboardService.contact$.pipe(distinctUntilKeyChanged("_id")),
      this.dashboardService.property$.pipe(distinctUntilKeyChanged("propertyId"))
    ]).pipe(
      switchMap(([contact, property]) => {
        this.isConsentTextLoading = true;
        return this.agentContactApiService
          .buildConsentLanguageForContact(contact._id, property.address, property.propertyId, AgentLeadType.CALLBACK)
          .pipe(
            tap(() => (this.isConsentTextLoading = false)),
            map(({consentLanguage}) => consentLanguage.value)
          );
      })
    );
  }).pipe(shareReplay(1));
  felloWebsites = FelloWebsites;

  constructor(
    private dialogRef: MatDialogRef<boolean>,
    @Inject(MAT_DIALOG_DATA) inputData: FelloUserContactDetails,
    private dashboardService: AbstractDashboardService,
    snackBar: MatSnackBar,
    spinner: NgxSpinnerService,
    private dashboardSettingsService: DashboardSettingsService,
    private consentService: ConsumerConsentService,
    private agentContactApiService: AgentContactDashboardApiService
  ) {
    super(spinner, snackBar);
    if (inputData) {
      this.data.firstName = inputData.fullName ? StringUtils.splitFullName(inputData.fullName).first : "";
      this.data.lastName = inputData.fullName ? StringUtils.splitFullName(inputData.fullName).last : "";
      this.data.phone = inputData.phone ?? "";
    }
  }

  trackContactAgentEvent(): void {
    this.withSpinnerAndToast(
      this.consentText$.pipe(
        take(1),
        switchMap(consentText => {
          return this.consentService.createConsentTracker(consentText);
        }),
        switchMap(({consentTrackerId}) => {
          this.data.consentTrackerId = consentTrackerId;
          return this.dashboardService.submitContactAgentForm(ChangelogEventType.SELLER_CLICKED_CONTACT_AGENT, this.data);
        })
      ),
      {
        successMsg: "Agent Contact Request Sent",
        failureMsg: "Something went wrong.Please Try Again.",
        snackBarConfig: {duration: 4000}
      }
    ).subscribe(() => {
      this.dialogRef.close();
    });
  }
}
