import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {map, shareReplay, switchMap, take, tap} from "rxjs/operators";
import {
  AddressComponent,
  AgentContactProperty,
  ContactDashboardSettingsResponse,
  HomeValueFromDashboardRequest,
  SaleTimeline
} from "fello-model";
import {
  AgentContactDashboardApiService,
  ConsumerConsentService,
  FelloWebsites,
  GoogleStreetViewImageService,
  StringUtils
} from "../../../../lib";
import {MatSnackBar} from "@angular/material/snack-bar";
import {phoneNumberPattern} from "../../../fello-ui-utils/constants/phoneNumberPattern";
import {SocialSettingsService} from "../../../fello-ui-lib";
import {defer, merge, NEVER, of} from "rxjs";

enum AddAddressSteps {
  Address_Entry,
  Address_Confirmation,
  Contact_Details
}

@Component({
  selector: "lib-add-address",
  templateUrl: "./agent-contact-add-address.component.html",
  styleUrls: ["./agent-contact-add-address.component.scss"]
})
export class AgentContactAddAddressComponent implements OnInit {
  address = "";
  phoneNumberPattern = phoneNumberPattern;
  addrComp: AddressComponent | null;
  contactId: string;
  isLoading = false;
  image: string | null;

  currentStep = AddAddressSteps.Address_Entry;
  addedProperty?: AgentContactProperty;
  AddAddressSteps = AddAddressSteps;

  homeValueFromDashboardRequest: HomeValueFromDashboardRequest = {
    firstName: "",
    lastName: "",
    phone: "",
    emailId: "",
    saleTimeline: undefined
  };
  settings?: ContactDashboardSettingsResponse;
  isConsentTextLoading = false;
  consentText$ = defer(() => {
    this.isConsentTextLoading = true;
    return this.agentContactApiService.buildConsentLanguageForContact(this.contactId, this.address).pipe(
      tap(() => (this.isConsentTextLoading = false)),
      map(({consentLanguage}) => consentLanguage.value),
      switchMap(consentText => merge(of(consentText), NEVER))
    );
  }).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true
    })
  );
  felloWebsites = FelloWebsites;
  SaleTimeline = SaleTimeline;

  constructor(
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private googleStreetViewImageService: GoogleStreetViewImageService,
    private router: Router,
    private agentContactApiService: AgentContactDashboardApiService,
    private socialSettingsService: SocialSettingsService,
    private consentService: ConsumerConsentService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.pipe(map(params => params.get("contactId"))).subscribe(contactId => {
      if (contactId) {
        this.contactId = contactId;
      }
    });

    this.route.data.subscribe(data => {
      this.settings = data.settings as ContactDashboardSettingsResponse;
      const {first, last} = StringUtils.splitFullName(this.settings.contactInfo.fullName);
      this.homeValueFromDashboardRequest = {
        phone: this.settings.contactInfo.phone,
        lastName: last,
        firstName: first,
        emailId: this.settings.contactInfo.email ?? ""
      };
      if (this.settings) {
        this.socialSettingsService.applySocialSettings({
          title: `Dashboard | ${this.settings.name ?? ""}`,
          description: "Personalized dashboard with tailored content for real time insights",
          image: "https://hifello.com/hubfs/connect/no_property_image.jpg"
        });
      }
    });
  }

  validateAddress(): void {
    this.addrComp = null;
    this.isLoading = true;
    this.image = null;
    this.agentContactApiService
      .validateAddress(this.contactId, this.address)
      .pipe(
        take(1),
        tap(a => {
          this.googleStreetViewImageService
            .getImage(a)
            .pipe(
              take(1),
              tap(image => {
                if (image) {
                  this.image = image;
                }
              })
            )
            .subscribe();
        })
      )
      .subscribe(
        addr => {
          this.addrComp = addr;
          this.isLoading = false;
          this.currentStep = AddAddressSteps.Address_Confirmation;
        },
        () => {
          this.snackBar.open("The address you selected is invalid. Please try with correct address", undefined, {
            duration: 2000
          });
          this.isLoading = false;
        }
      );
  }
  addAddress(): void {
    this.currentStep = AddAddressSteps.Contact_Details;
  }

  submitHomeValueForm(): void {
    const homeValueRequest = {...this.homeValueFromDashboardRequest};
    if (!homeValueRequest.phone) {
      delete homeValueRequest.phone;
    }
    this.isLoading = true;

    this.consentText$
      .pipe(
        take(1),
        switchMap(consentText => {
          return this.consentService.createConsentTracker(consentText);
        }),
        switchMap(({consentTrackerId}) => {
          homeValueRequest.consentTrackerId = consentTrackerId;
          return this.agentContactApiService.addAddress(this.contactId, this.address, homeValueRequest);
        })
      )
      .subscribe(property => {
        this.router.navigate(["/dashboard", property.propertyId]);
      });
  }

  scrollToError() {
    setTimeout(() => {
      const el = document.getElementsByClassName("error-field")[0];
      if (el) {
        el.scrollIntoView({behavior: "smooth", block: "center", inline: "end"});
      }
    }, 100);
  }
}
