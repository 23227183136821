import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LeadRequestFromDashboard, PopupLeadFormRequest, SaleTimeline} from "fello-model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgxSpinnerService} from "ngx-spinner";
import {AgentContactDashboardApiService, ConsumerConsentService, FelloWebsites, StringUtils, ToastSpinnerBase} from "../../../../../lib";
import {distinctUntilKeyChanged, map, shareReplay, switchMap, take, tap} from "rxjs/operators";
import {phoneNumberPattern} from "../../../../fello-ui-utils";
import {AbstractDashboardService, DashboardSettingsService} from "../../../services";
import {combineLatest, defer, merge, NEVER, of} from "rxjs";

@Component({
  selector: "lib-popup-lead-form",
  templateUrl: "./popup-lead-form.component.html",
  styleUrls: ["./popup-lead-form.component.scss"]
})
export class PopupLeadFormComponent extends ToastSpinnerBase {
  protected readonly SaleTimeline = SaleTimeline;
  data: LeadRequestFromDashboard = {
    firstName: "",
    lastName: "",
    phone: "",
    message: ""
  };
  phoneNumberPattern = phoneNumberPattern;
  settings$ = this.dashboardSettingsService.settings$;
  isConsentTextLoading = false;
  consentText$ = defer(() => {
    return combineLatest([
      this.dashboardService.contact$.pipe(distinctUntilKeyChanged("_id")),
      this.dashboardService.property$.pipe(distinctUntilKeyChanged("propertyId"))
    ]).pipe(
      switchMap(([contact, property]) => {
        this.isConsentTextLoading = true;
        return this.agentContactApiService
          .buildConsentLanguageForContact(contact._id, property.address, property.propertyId, this.inputData.action.leadType)
          .pipe(
            tap(() => (this.isConsentTextLoading = false)),
            map(({consentLanguage}) => consentLanguage.value),
            switchMap(consentText => merge(of(consentText), NEVER))
          );
      })
    );
  }).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true
    })
  );
  felloWebsites = FelloWebsites;
  contactEmail = "";
  constructor(
    private dialogRef: MatDialogRef<boolean>,
    @Inject(MAT_DIALOG_DATA) public inputData: PopupLeadFormRequest,
    private dashboardService: AbstractDashboardService,
    snackBar: MatSnackBar,
    spinner: NgxSpinnerService,
    private dashboardSettingsService: DashboardSettingsService,
    private consentService: ConsumerConsentService,
    private agentContactApiService: AgentContactDashboardApiService
  ) {
    super(spinner, snackBar);

    this.dashboardService.contact$
      .pipe(
        tap(contact => {
          this.data.firstName = contact.fullName ? StringUtils.splitFullName(contact.fullName).first : "";
          this.data.lastName = contact.fullName ? StringUtils.splitFullName(contact.fullName).last : "";
          this.data.phone = contact.phone ?? "";
          this.contactEmail = contact.emailId ?? "";
        })
      )
      .subscribe();
  }

  submitCTAPopupLeadForm(): void {
    this.withSpinnerAndToast(
      this.consentText$.pipe(
        take(1),
        switchMap(consentText => {
          return this.consentService.createConsentTracker(consentText);
        }),
        switchMap(({consentTrackerId}) => {
          this.data.consentTrackerId = consentTrackerId;
          return this.dashboardService.submitCTAPopupLeadForm(this.inputData.action, this.data, this.inputData.connectedAppType);
        })
      ),
      {
        successMsg: "Message received, we'll get back to you shortly",
        failureMsg: "Something went wrong.Please Try Again.",
        snackBarConfig: {duration: 4000}
      }
    ).subscribe(() => {
      this.dialogRef.close();
    });
  }
}
