import {Inject, Injectable} from "@angular/core";
import {map, takeUntil} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {AgencyThemeSetting, DashboardSettingsResponse} from "fello-model";
import {DOCUMENT} from "@angular/common";
import {CustomThemeManager} from "../../fello-ui-utils";
import {mixinDestroyable} from "../../../lib";
import {TrackingCodeManagerService} from "../../fello-ui-lib";
import {AbstractDashboardSettingsService} from "./abstract-dashboard-settings.service";

@Injectable()
export class DashboardSettingsService extends mixinDestroyable(AbstractDashboardSettingsService) {
  settings$ = this.activatedRoute.data.pipe(map(({settings}) => settings as DashboardSettingsResponse | null));
  private _customThemeManager: CustomThemeManager;

  constructor(
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) _document: Document,
    private trackingCodeManagerService: TrackingCodeManagerService
  ) {
    super();
    this._customThemeManager = new CustomThemeManager(_document);
    this.settings$.pipe(takeUntil(this.isDestroyed)).subscribe(settings => {
      this._applyTheme(settings?.theme);
      this.trackingCodeManagerService.addTrackingCode(settings?.globalTrackingSetting?.trackingCode);
      this.trackingCodeManagerService.addTrackingCode(settings?.config.generalSettings?.trackingCode);
    });
  }

  protected _applyTheme(theme?: AgencyThemeSetting, divId?: string): void {
    this._customThemeManager.applyTheme(theme, divId);
  }
}
