import {
  AgencyBranding,
  ContactDashboardSettingsResponse,
  DashboardSettingsResponse,
  ImageMeta,
  JourneySettingResponse,
  PostcardDashboardSettingResponse
} from "fello-model";
import {isEmpty} from "lodash-es";
import {RegexUtils} from "../../modules/fello-ui-utils/constants/RegexUtils";

export class AgentBrandingUtils {
  static MAX_LOGOS_ALLOWED = 2;

  static isImageValidForPrint(logoMeta: ImageMeta): boolean {
    return logoMeta.height >= 1000 && logoMeta.width >= 1000;
  }
  static isPrintSettingConfigured(branding: AgencyBranding): boolean {
    const setting = branding.value.settings;
    return (
      !isEmpty(setting.printSettings.returnName) && !isEmpty(setting.printSettings.returnAddress) && !isEmpty(setting.printSettings.logo)
    );
  }
  static getAgencyWebsiteDisplayText(website?: string): string {
    return (website ?? "").replace(RegexUtils.URL_START, "").split("?")[0];
  }
  static getAgencyConsentText(
    settings:
      | DashboardSettingsResponse
      | JourneySettingResponse
      | ContactDashboardSettingsResponse
      | PostcardDashboardSettingResponse
      | null
  ) {
    if (settings?.consentText) {
      return settings?.consentText;
    }
    return `<span>By submitting your information, you consent to receive communications from ${
      settings?.name ?? ""
    } or its authorized third-party representatives about services, including marketing and
      informational messages, via email, phone, and text. These may be sent using automated technology, such as autodialed calls,
      pre-recorded or artificial voice messages, and text messages. You can save a copy of these terms using your browser’s save or print
      function. Providing this consent is not a condition of purchasing goods or services.</span>`;
  }
}
