import {Component, Input} from "@angular/core";
import {AgencyLender, LenderType} from "fello-model";

@Component({
  selector: "lib-lender-details",
  templateUrl: "./lender-details.component.html",
  styleUrls: ["./lender-details.component.scss"]
})
export class LenderDetailsComponent {
  @Input() lender: AgencyLender;
  LenderType = LenderType;
}
