import {NgModule} from "@angular/core";
import {AgentSignatureComponent} from "./components/agent-signature/agent-signature.component";
import {CommonModule} from "@angular/common";
import {FelloUiUtilsModule} from "../fello-ui-utils";

@NgModule({
  declarations: [AgentSignatureComponent],
  imports: [CommonModule, FelloUiUtilsModule],
  exports: [AgentSignatureComponent],
  providers: []
})
export class SignatureModule {}
