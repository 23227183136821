import {Observable} from "rxjs";
import {
  AgencyBillingCreditAddon,
  AgencyBillingDetails,
  AgencyBillingDetailsAndUsage,
  AgencyCMSSettingUpdateRequest,
  AgencyContactTierUpdateResponse,
  AgencyCreditsAddonUpdateResponse,
  AgencyExpiringCredits,
  BillingDetailsChargebeeInvoiceEntry,
  ChargebeeHostedPage,
  PurchaseAgreement
} from "fello-model";
import {Inject, Injectable, InjectionToken} from "@angular/core";
import {HttpClient} from "@angular/common/http";
export const PAYMENT_API_SERVICE_BASE_URL = new InjectionToken<string>("PAYMENT_API_SERVICE_BASE_URL");

@Injectable({
  providedIn: "root"
})
export class AgencyBillingService {
  baseUrl: string;

  constructor(private http: HttpClient, @Inject(PAYMENT_API_SERVICE_BASE_URL) private paymentBaseUrl: string) {
    this.baseUrl = `${this.paymentBaseUrl}/payment/agency/billing`;
  }
  getBillingUsageDetails(): Observable<AgencyBillingDetailsAndUsage> {
    return this.http.get<AgencyBillingDetailsAndUsage>(`${this.baseUrl}/details`, {});
  }
  getExpiringCreditsDetails(): Observable<AgencyExpiringCredits[]> {
    return this.http.get<AgencyExpiringCredits[]>(`${this.baseUrl}/expiring-credits`, {});
  }

  getBillingInvoices(): Observable<BillingDetailsChargebeeInvoiceEntry[]> {
    return this.http.get<BillingDetailsChargebeeInvoiceEntry[]>(`${this.baseUrl}/invoices`, {});
  }

  getPurchaseAgreements(): Observable<PurchaseAgreement[]> {
    return this.http.get<PurchaseAgreement[]>(`${this.baseUrl}/purchase-agreement`, {});
  }

  getDownloadUrlForInvoice(invoiceId: string): Observable<{url: string | null}> {
    return this.http.post<{url: string | null}>(`${this.baseUrl}/invoices/${invoiceId}`, {});
  }
  getScheduledChanges(): Observable<AgencyBillingDetails> {
    return this.http.get<AgencyBillingDetails>(`${this.baseUrl}/scheduled_changes`, {});
  }
  updateMarketingContactAddon(quantity: number): Observable<AgencyContactTierUpdateResponse> {
    return this.http.post<AgencyContactTierUpdateResponse>(`${this.baseUrl}/addon/marketing-contact`, {quantity});
  }

  getAllCreditAddons(): Observable<AgencyBillingCreditAddon[]> {
    return this.http.get<AgencyBillingCreditAddon[]>(`${this.baseUrl}/addon/credit`, {});
  }
  updateCreditAddon(
    quantity: number,
    itemPriceId: string,
    agencyCMSSettingUpdateRequest: AgencyCMSSettingUpdateRequest
  ): Observable<AgencyCreditsAddonUpdateResponse> {
    return this.http.post<AgencyCreditsAddonUpdateResponse>(`${this.baseUrl}/addon/credit`, {
      quantity,
      itemPriceId,
      agencyCMSSettingUpdateRequest
    });
  }

  getManagePaymentPage(): Observable<ChargebeeHostedPage> {
    return this.http.get<ChargebeeHostedPage>(`${this.baseUrl}/manage-payment-page`);
  }
}
