export enum CMSDebitReason {
  DOWNGRADE_VIA_PRORATION = "DOWNGRADE_VIA_PRORATION",
  ENGAGE = "ENGAGE",
  EMAIL = "EMAIL",
  LEAD_MARKETPLACE = "LEAD_MARKETPLACE",
  POSTCARD = "POSTCARD",
  WEBHOOK = "WEBHOOK",
  ENRICH = "ENRICH",
  DNC = "DNC"
  // AI = "AI"
}

export enum CMSCreditReason {
  PRORATION = "PRORATION",
  PURCHASE = "PURCHASE",
  AUTO_RECHARGE = "AUTO_RECHARGE",
  /**
   * @deprecated This is kept only because there are older values in db (specially cms history).
   * prefer using PLAN_RENEWAL
   */
  MONTHLY_RENEWAL = "MONTHLY_RENEWAL",
  PLAN_RENEWAL = "PLAN_RENEWAL",
  TURBO = "TURBO",

  EMAIL_PO_DOMAIN_BUCKET = "EMAIL_PO_DOMAIN_BUCKET",
}

export const CMSCreditReasonLabel: Record<CMSCreditReason, string> = {
  [CMSCreditReason.PRORATION]: "Subscription Credit Allocation (Prorated)",
  [CMSCreditReason.PURCHASE]: "Purchased from Fello",
  [CMSCreditReason.AUTO_RECHARGE]: "Auto-recharge",
  [CMSCreditReason.MONTHLY_RENEWAL]: "Subscription Credit Allocation",
  [CMSCreditReason.PLAN_RENEWAL]: "Subscription Credit Allocation",
  [CMSCreditReason.TURBO]: "Added by Fello Support",
  [CMSCreditReason.EMAIL_PO_DOMAIN_BUCKET]: " ",
};
