import {Inject, Injectable, InjectionToken} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {FelloTemplateSegmentType} from "fello-model";

export const AGENT_API_CONSUMER_BASE_URL = new InjectionToken<string>("AGENT_API_CONSUMER_BASE_URL");

@Injectable({
  providedIn: "root"
})
export class ConsumerConsentService {
  private apiBaseUrl: string;

  constructor(private http: HttpClient, @Inject(AGENT_API_CONSUMER_BASE_URL) private agentBaseConsumerUrl: string) {
    this.apiBaseUrl = `${this.agentBaseConsumerUrl}/agent/consent`;
  }

  createConsentTracker(consentText: string): Observable<{consentTrackerId: string}> {
    const consentTrackerPayload = {
      websiteUrl: window.location.href,
      consentLanguage: {type: FelloTemplateSegmentType.HTML, value: consentText}
    };
    return this.http.post<{consentTrackerId: string}>(`${this.apiBaseUrl}/tracker`, consentTrackerPayload);
  }
}
