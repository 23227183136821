import {Inject, Injectable, Optional} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AGENT_API_CONSUMER_BASE_URL, AGENT_API_SERVICE_BASE_URL} from "../services";
import {some} from "lodash-es";
import {ErrorCode, FelloApiErrorResponse, FelloApiFailureResponse, FelloApiSuccessfulResponse} from "fello-model";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable()
export class FelloApiResponseInterceptor implements HttpInterceptor {
  private baseUrls: string[] = [];

  constructor(
    private snackbar: MatSnackBar,
    @Optional() @Inject(AGENT_API_SERVICE_BASE_URL) agentApiBaseUrl?: string,
    @Optional() @Inject(AGENT_API_CONSUMER_BASE_URL) agentConsumerApiBaseUrl?: string
  ) {
    if (agentApiBaseUrl) {
      this.baseUrls.push(agentApiBaseUrl);
    }
    if (agentConsumerApiBaseUrl) {
      this.baseUrls.push(agentConsumerApiBaseUrl);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const requestUrl = req.url;
    if (some(this.baseUrls, baseUrl => requestUrl.includes(baseUrl))) {
      return next.handle(req).pipe(
        map((response: any) => {
          if (this.checkFelloApiResponseFormat(response) === true) {
            if (response.body.success === true) {
              return new HttpResponse<unknown>({...response, body: response.body.data});
            } else {
              if (response.body.error.code === ErrorCode.NOT_AUTHORISED) {
                this.snackbar.open(response.body.data ?? `Not-Authorised`, undefined, {duration: 3000});
              }
              throw new FelloApiErrorResponse<unknown>(response.body.error, response.body.data);
            }
          }
          return response;
        })
      );
    }
    return next.handle(req);
  }

  checkFelloApiResponseFormat(
    response: HttpEvent<any>
  ): response is HttpResponse<FelloApiSuccessfulResponse<any> | FelloApiFailureResponse<any>> {
    return (
      response instanceof HttpResponse &&
      response.body != null &&
      "success" in response.body &&
      (response.body.success === true || response.body.success === false) &&
      ((response.body.success === false && response.body.error && "code" in response.body.error && "message" in response.body.error) ||
        response.body.success === true)
    );
  }
}
