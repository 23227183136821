import {Component, OnInit} from "@angular/core";
import {combineLatest, defer, merge, NEVER, Observable, of, switchMap} from "rxjs";
import {distinctUntilKeyChanged, map, shareReplay, take, takeUntil, tap} from "rxjs/operators";
import {find} from "lodash-es";
import {Router} from "@angular/router";
import {
  AgentContactDashboardApiService,
  ConsumerConsentService,
  DestroyableToastSpinnerBase,
  FelloWebsites,
  GoogleStreetViewImageService,
  PropertyPublicDashboardApiService,
  StringUtils
} from "../../../../../lib";
import {MatDialogRef} from "@angular/material/dialog";
import {AbstractDashboardService, DashboardSettingsService} from "../../../services";
import {
  AgentContactProperty,
  ClaimStatus,
  ContactDetailsOnlyFormConfigSettings,
  HomeValueFromDashboardRequest,
  SaleTimeline
} from "fello-model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgxSpinnerService} from "ngx-spinner";
import {phoneNumberPattern} from "../../../../fello-ui-utils";

enum ManageAddressSteps {
  Address_List,
  Confirm_Contact_Details
}

@Component({
  selector: "lib-manage-address-dialog",
  templateUrl: "./manage-address-dialog.component.html",
  styleUrls: ["./manage-address-dialog.component.scss"]
})
export class ManageAddressDialogComponent extends DestroyableToastSpinnerBase implements OnInit {
  ClaimStatus = ClaimStatus;
  SaleTimeline = SaleTimeline;
  phoneNumberPattern = phoneNumberPattern;
  ManageAddressSteps = ManageAddressSteps;
  currentStep = ManageAddressSteps.Address_List;
  selectedPropertyId: string;
  selectedProperty: AgentContactProperty;
  settings$ = this.dashboardSettingsService.settings$;
  isConsentTextLoading = false;
  consentText$ = defer(() => {
    return this.dashboardService.contact$.pipe(
      distinctUntilKeyChanged("_id"),
      switchMap(contact => {
        this.isConsentTextLoading = true;
        return this.agentContactApiService
          .buildConsentLanguageForContact(contact._id, this.selectedProperty.address, this.selectedPropertyId)
          .pipe(
            tap(() => (this.isConsentTextLoading = false)),
            map(({consentLanguage}) => consentLanguage.value),
            switchMap(consentText => merge(of(consentText), NEVER))
          );
      })
    );
  }).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true
    })
  );
  propertiesList$ = this.dashboardService.contact$.pipe(map(contact => contact.properties));
  currentProperty$ = this.dashboardService.property$;
  contact$ = this.dashboardService.contact$;
  felloWebsites = FelloWebsites;

  availableProperties$ = combineLatest([this.propertiesList$, this.currentProperty$]).pipe(
    map(([allDashboards, currentDashboard]) => {
      if (!find(allDashboards, dashboard => dashboard.propertyId === currentDashboard.propertyId)) {
        return [currentDashboard, ...allDashboards];
      }
      return allDashboards;
    })
  );

  homeValueFromDashboardRequest: HomeValueFromDashboardRequest = {
    firstName: "",
    lastName: "",
    phone: "",
    emailId: "",
    saleTimeline: undefined
  };
  isLoading = false;
  hvFormSettings?: ContactDetailsOnlyFormConfigSettings;

  constructor(
    private dashboardService: AbstractDashboardService,
    public streetView: GoogleStreetViewImageService,
    private agentContactApiService: AgentContactDashboardApiService,
    private propertyPublicDashboardApiService: PropertyPublicDashboardApiService,
    private dashboardSettingsService: DashboardSettingsService,
    private router: Router,
    private dialogRef: MatDialogRef<any>,
    snackBar: MatSnackBar,
    spinnerService: NgxSpinnerService,
    private consentService: ConsumerConsentService
  ) {
    super(spinnerService, snackBar);
  }

  ngOnInit(): void {
    this.currentProperty$
      .pipe(
        takeUntil(this.isDestroyed),
        tap(property => {
          this.selectedPropertyId = property.propertyId;
          this.selectedProperty = property;
        })
      )
      .subscribe();

    this.dashboardService.contact$
      .pipe(
        takeUntil(this.isDestroyed),
        tap(contact => {
          const {first, last} = StringUtils.splitFullName(contact.fullName);
          this.homeValueFromDashboardRequest = {
            phone: contact.phone,
            lastName: last,
            firstName: first,
            emailId: contact.emailId ?? ""
          };
        })
      )
      .subscribe();

    this.currentProperty$
      .pipe(
        take(1),
        switchMap(property => {
          return this.propertyPublicDashboardApiService.getHVFormSettings(property.propertyId);
        }),
        tap(hvFormSettings => (this.hvFormSettings = hvFormSettings))
      )
      .subscribe();
  }

  goToDashboard(propertyId: string) {
    this.dialogRef.close();
    this.router.navigate(["dashboard", propertyId]);
  }

  addNewAddress(
    addAddressAction?: (
      propertyId: string,
      rawAddress: string,
      hvRequestFromDashboard: HomeValueFromDashboardRequest
    ) => Observable<AgentContactProperty>
  ) {
    this.dashboardService
      .addNewAddress(addAddressAction)
      .pipe(
        tap(isAddressAdded => {
          if (isAddressAdded) {
            this.dialogRef.close(true);
          }
        })
      )
      .subscribe();
  }

  unClaimClaimedProperty(property: AgentContactProperty) {
    this.dashboardService
      .unClaimClaimedProperty(property)
      .pipe(
        tap(isAddressUnClaimed => {
          if (isAddressUnClaimed) {
            this.dialogRef.close(true);
          }
        })
      )
      .subscribe();
  }

  unClaimUnClaimedProperty(property: AgentContactProperty) {
    this.dashboardService
      .unClaimUnClaimedProperty(property)
      .pipe(
        tap(isAddressUnClaimed => {
          if (isAddressUnClaimed) {
            this.dialogRef.close(true);
          }
        })
      )
      .subscribe();
  }

  claimAddress() {
    const homeValueRequest = {...this.homeValueFromDashboardRequest};
    if (!homeValueRequest.phone) {
      delete homeValueRequest.phone;
    }
    this.isLoading = true;
    this.consentText$
      .pipe(
        take(1),
        switchMap(consentText => {
          return this.consentService.createConsentTracker(consentText);
        }),
        switchMap(({consentTrackerId}) => {
          homeValueRequest.consentTrackerId = consentTrackerId;
          return this.agentContactApiService.claimAddressFromDashboard(this.selectedPropertyId, homeValueRequest);
        })
      )
      .subscribe(() => {
        this.router.navigate(["/dashboard", this.selectedPropertyId]);
        this.dialogRef.close();
      });
  }
}
