export enum ErrorCode {
  MISSING_USER = "MISSING_USER",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  NOT_AUTHORISED = "NOT_AUTHORISED",
  CONTACT_EMAIL_ALREADY_EXISTS = "CONTACT_EMAIL_ALREADY_EXISTS",
  AGENCY_NOT_SETUP = "AGENCY_NOT_SETUP",
  INVALID_TOKEN = "INVALID_TOKEN",
  INVALID_INVITATION_CODE = "INVALID_INVITATION_CODE",
  APP_SIERRA_ACCOUNT_EMAIL_INVALID = "APP_SIERRA_ACCOUNT_EMAIL_INVALID",
  APP_BOLDTRAIL_ACCOUNT_USERID_INVALID = "APP_BOLDTRAIL_ACCOUNT_USERID_INVALID",
  INVALID_AUTOMATION_COMPONENT_SETTINGS = "INVALID_AUTOMATION_COMPONENT_SETTINGS"
}
