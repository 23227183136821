import {BuyingWithSellingOptions} from "../enums/BuyingWithSellingOptions";
import {HomeConditionLevel} from "../enums/HomeConditionLevel";
import {SaleTimeline} from "../enums/SaleTimeline";
import {FelloCustomAddressEntry} from "./FelloCustomAddressEntry";
import {AgentFindingStep} from "../enums/AgentFindingStep";
import {AgentLeadType} from "../../agent";
import {BuyingProgress} from "../FelloDashboard";

export enum WallPaintColorIntensity {
  WALLS_NEUTRAL = "WALLS_NEUTRAL",
  NO_WALLS_NEUTRAL = "NO_WALLS_NEUTRAL",
  MIX_MOSTLY_NEUTRAL = "MIX_MOSTLY_NEUTRAL",
  MIX_MOSTLY_BOLD = "MIX_MOSTLY_BOLD",
  WALLPAPER = "WALLPAPER"
}
export const WallPaintColorIntensityLabel: Record<WallPaintColorIntensity, string> = {
  [WallPaintColorIntensity.WALLS_NEUTRAL]: "Yes, walls are neutral",
  [WallPaintColorIntensity.NO_WALLS_NEUTRAL]: "No, no walls are neutral",
  [WallPaintColorIntensity.MIX_MOSTLY_NEUTRAL]: "Mix, but mostly neutral",
  [WallPaintColorIntensity.MIX_MOSTLY_BOLD]: "Mix, but mostly bold",
  [WallPaintColorIntensity.WALLPAPER]: "Wallpaper"
};

export interface FelloFormChoiceVisitorVar {
  formId: string;
  // @MaxLength(64)
  name: string;
  title: string;
  // @MaxLength(480)
  description?: string;
  leadType: AgentLeadType;
}

export interface FelloVisitorVariables {
  // @MinLength(4)
  // @MaxLength(64)
  userFullName?: string;
  address?: string;
  httpReferrer?: string;
  addressAck?: boolean;
  _a?: number;
  _dedupeDashboardId?: string;
  _serv?: boolean;
  _ioServ?: boolean;
  customAddress?: FelloCustomAddressEntry;
  saleTimeline?: SaleTimeline;

  homeWorth?: string;

  homeWorthExactAmount?: number;

  userEmail?: string;

  userPhone?: string;

  beds?: number;

  baths?: number;

  partialBaths?: number;
  parkingCount?: number;

  sqft?: number;

  storiesCount?: number;
  yearBuilt?: number;
  lotSizeAcres?: number;
  basementPresent?: boolean;
  basementFinishedPerc?: number;
  img?: string;
  kitchenCondition?: HomeConditionLevel;
  bathroomCondition?: HomeConditionLevel;
  flooringCondition?: HomeConditionLevel;
  homePaintCondition?: HomeConditionLevel;
  overallCondition?: HomeConditionLevel;
  additionalBathroomCondition?: HomeConditionLevel;
  kitchenRemodeled?: boolean;
  bathsRemodeled?: boolean;
  partialBathsRemodeled?: boolean;
  wallPaintColorIntensity?: WallPaintColorIntensity;
  hoa?: boolean;
  wellWater?: boolean;
  septicSystem?: boolean;
  solarPanels?: boolean;
  swimmingPool?: boolean;
  homeSecurity?: boolean;
  hotTubSpa?: boolean;
  // @MaxLength(1024)
  homeRemarks?: string;
  // @MaxLength(1024)
  howDidYouHear?: string;
  futureSellerContinue?: boolean;
  buyingWithSelling?: BuyingWithSellingOptions;
  homeFactsConsent?: boolean;
  buyingProgress?: BuyingProgress;
  sellerInvitesAgent?: boolean;
  sellerAgentFindingProcess?: AgentFindingStep;
  buyingArea?: string;
  consentLanguage?: string;
  // @Min(0)
  // @Max(2_000_000)
  buyingPriceLow?: number;
  // @Min(0)
  // @Max(2_000_000)
  buyingPriceHigh?: number;

  // Agent Varibales
  agGetStartedSelection?: boolean;
  // @MaxLength(64)
  agBrokerage?: string;
  // @MaxLength(32)
  agMlsId?: string;
  agInvitedSellerForHomeDetails?: boolean;
  skipOfferToSeller?: boolean;
  sellerLastInvitedAt?: string;
  // @MaxLength(128)
  invitedSellerEmailSubject?: string;
  // @MaxLength(512)
  invitedSellerEmailBody?: string;
  invitedAgentFullName?: string;
  invitedAgentEmail?: string;
  invitedAgentPhone?: string;
  journeyCompleted?: boolean;
  formChoices?: FelloFormChoiceVisitorVar[];
  selectedFormId?: string;
  widgetId?: string;
  lpId?: string;
  originUrl?: string;
  consentTrackerId?: string;
}
